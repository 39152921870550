//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PasswordInput',
  model: {
    event: 'input',
    prop: 'value',
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    rules: {
      type: Object,
      default: () => ({}),
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '48px',
    },
    width: {
      type: String,
      default: '100%',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
    settings: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      type: 'password',
    }
  },
  computed: {
    options() {
      return {
        value: this.value,
        rules: this.rules,
        errors: this.errors,
        type: this.type,
        disabled: this.disabled,
        placeholder: this.placeholder,
        label: this.label,
        settings: this.settings,
      }
    },
  },
  methods: {
    onChangeType(e) {
      e.preventDefault()
      this.type === 'password' ? (this.type = 'text') : (this.type = 'password')
    },
    onInput(value) {
      if (this.errors) {
        this.$emit('changeError', this.id)
      }
      this.$emit('input', value)
    },
  },
}
