//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ModalOverlay',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '500px',
    },
    padding: {
      type: String,
      default: '',
    },
  },
}
