//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Popover from './Popover.vue'
export default {
  name: 'Paginations',
  components: { Popover },
  model: {
    event: 'click',
    prop: 'page',
  },
  props: {
    type: {
      type: String,
      default: 'center',
    },
    count: {
      type: Number,
      default: null,
    },
    pages: {
      type: [String, Number],
      default: 10,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    countItems: {
      type: Number,
      default: 0,
    },
    offset: {
      type: Array,
      default: () => [],
    },
    settings: {
      type: Object,
      default: () => ({
        counter: true,
        offset: true,
      }),
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  watch: {
    currentPage: {
      handler(value) {
        this.onSelect(value)
      },
    },
  },
  computed: {
    counter() {
      return `${(this.page - 1) * this.perPage + 1}-${(this.page - 1) * this.perPage + this.countItems} ${this.$t('of')} ${this.total}`
    },
    isPrevArrow() {
      return this.page <= 1
    },
    isNextArrow() {
      return this.page >= this.pages
    },
    classes() {
      return {
        center: this.type === 'center',
        left: this.type === 'left',
        right: this.type === 'right',
      }
    },
    visiblePagination() {
      return this.$props.total > this.$props.offset[0]
    },
  },
  methods: {
    onSelect(page) {
      this.$emit('click', page)
    },
    onPrev() {
      let page = this.page
      page = page <= 1 ? 1 : page - 1
      this.$emit('click', page)
    },
    onNext() {
      let page = this.page
      page = page >= this.pages ? this.pages : page + 1
      this.$emit('click', page)
    },
    onSelectPageOffset(value) {
      this.$emit('select:offset', value)
      if(this.$props.page === 1) {
        this.onSelect(this.$props.page)
      }
    },
    disabledOffset(perPage) {
      return this.$props.page * perPage >= this.$props.total
    },
  },
}
