//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Search from './Search.vue'
import SearchList from './SearchList.vue'
export default {
  name: 'SearchWrapper',
  components: {
    Search,
    SearchList,
  },
  data() {
    return {
      search: '',
      items: [],
      searchHistory: [],
      total: 0,
      open: false,
      isPending: false,
    }
  },

  computed: {
    searchListAttrs() {
      return {
        items: this.items,
        search: this.search,
        searchHistory: this.searchHistory,
        isPending: this.isPending,
        total: this.total,
      }
    },
  },
  watch: {
    isPending(val) {
      if (val) {
        this.open = true
      }
    },
  },

  methods: {
    onChange(value) {
      this.search = value
      const params = {
        name: value,
        per_page: 5,
        page: 1,
      }
      if (value.length < 4) {
        this.items = []
        this.open = false
        return
      }
      this.isPending = true
      this.fetchSearchValues(params)
    },
    onClose() {
      this.open = false
    },
    onClear() {
      this.items = []
      this.open = false
    },
    onFocus() {
      this.searchHistory = JSON.parse(localStorage.getItem('history')) || []
    },
    onClickHistory(value) {
      this.search = value
      if (this.search.length >= 4) {
        const params = {
          name: this.search,
          per_page: 5,
          page: 1,
        }
        this.fetchSearchValues(params)
      }
      this.onClose()
    },
    onClick() {
      if (this.items.length || this.searchHistory.length) {
        this.open = true
      }
    },

    setSearchHistory(item) {
      let history = []
      if (this.searchHistory.length) {
        history = this.searchHistory
        if (!history.includes(item)) history.unshift(item)
        localStorage.setItem('history', JSON.stringify(history.slice(0, 5)))
      } else {
        history.unshift(item)
        localStorage.setItem('history', JSON.stringify(history))
      }
    },

    fetchSearchValues(params) {
      return this.$request
        .cancelable('search')
        .$get('search', params)
        .then(({ data, meta }) => {
          this.items = data.splice(0, 3).map((item) => ({
            ...item,
            picture: item.files.length ? item.files[0].preview_url : '',
          }))
          this.total = +meta.pagination.total
        })
        .finally(() => {
          this.isPending = false
        })
    },
  },
}
