//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getErrorsForm from '~/mixins/getErrorsForm'
import { validationMixin } from 'vuelidate'
import {
  helpers,
  required,
  numeric,
  minLength,
  maxLength,
  email,
  sameAs,
} from 'vuelidate/lib/validators'
import validationWatcher from '~/mixins/validationWatcher'
import IconCrosshair from '~/components/icons/IconCrosshair.vue'
import IconBase from '~/components/GlobalUI/IconBase.vue'
import { GET_USER } from '~/store/user/types.user'

const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\-\s]*$/)

export default {
  components: {
    IconBase,
    IconCrosshair,
  },
  mixins: [validationMixin, validationWatcher, getErrorsForm],
  data() {
    return {
      form: {
        email: '',
        phone: '',
        agreement: true,
      },
    }
  },
  validations() {
    const rules = {
      form: {
        email: {
          required,
          email,
          minLength: minLength(5),
          maxLength: maxLength(64),
          emailValidationText,
        },
        phone: {
          minLength: minLength(10),
        },
        agreement: {
          required: sameAs(() => true),
        },
      },
    }

    return rules
  },
  methods: {
    showModal() {
      this.$refs['newsletter'].show()
    },
    hideModal() {
      this.$refs['newsletter'].hide()
    },

    async onSubmit() {
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      this.setValidationSettings(false)

      const data = {
        email: this.form.email,
        phone: this.form.phone.replace(/[^+0-9]/g, ''),
      }

      try {
        await this.$api.newsletter.subscribe(data)
        this.hideModal()
        localStorage.setItem('newsletter', JSON.stringify(true))
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters[`user/${GET_USER}`]
    },
  },

  mounted() {
    //
    const isAuthUserSubscribed = this.user?.subscribe
    const isSubscribed = localStorage.getItem('newsletter')

    if (isSubscribed && isAuthUserSubscribed) return

    // if user is subscribed but localStorage is empty
    if (isAuthUserSubscribed && !isSubscribed) {
      localStorage.setItem('newsletter', JSON.stringify(true))
      return
    }

    if (isSubscribed) return

    setTimeout(() => {
      this.showModal()
    }, 10000)
  },
}
