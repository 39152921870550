//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Input from '~/components/UI/Input.vue'
export default {
  name: 'Search',
  components: { Input },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: '',
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value)
    },

    onClose() {
      this.$emit('close')
    },

    onClick() {
      this.$emit('click:input')
    },

    onClear() {
      this.$emit('clear')
    },
    onSubmit() {
      this.$router.push(
        this.localePath({
          name: 'search-query',
          params: { query: this.value },
        }),
      )
      this.$emit('set:history', this.value)
      this.onClose()
    },
  },
}
