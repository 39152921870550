//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalOverlay from './ModalOverlay.vue'
export default {
  components: { ModalOverlay },
  props: {
    onlyClose: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '500px',
    },
  },
  data() {
    return {
      modal: false,
    }
  },
  watch: {
    modal() {
      const wrapper = document.body.querySelector('.app-wrapper')
      this.modal
        ? wrapper.classList.add('blur-effect')
        : wrapper.classList.remove('blur-effect')
    },
  },
  methods: {
    onClose() {
      this.modal = false
      this.$emit('close')
    },

    onOpen() {
      this.modal = true
    },
  },
}
